html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.tile {
  position: absolute;
  box-sizing: border-box;
  border-radius: 2px;
  border-width: 5px;
  border-style: outset;
  border-left-color: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.33);
  border-right-color: rgba(0, 0, 0, 0.15);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  cursor: pointer;
  /* See https://stackoverflow.com/questions/25704650 */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
